const cognitoSettings = {
  REGION: "eu-north-1",
  USER_POOL_ID: "eu-north-1_byCqdjBQR",
  APP_CLIENT_ID: "3j89oh8qgaejf5nlhv68jo5b64",
  IDENTITY_POOL_ID: "eu-north-1:287b8b9c-b9de-481f-b2a4-f9dfe4c01f7f",
}

const dev = {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "eu-north-1",
    URL: "https://dev-api.pwd.tornyak.com"
  },
  s3: {
    BUCKET: "phoapp-dev",
    REGION: "eu-north-1",
  }
}

const prod =  {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "eu-north-1",
    URL: "https://api.pwd.tornyak.com",
  },
  s3: {
    BUCKET: "phoapp",
    REGION: "eu-north-1",
  }
}

export const APINAME="photos"

export default process.env.NODE_ENV === "development" ? dev : prod;
